
import React, { useEffect } from 'react'
import { Helmet } from "react-helmet";
import Layout from '../components/Layout';
import SEO from "../components/SEO";
import Theme, { theme } from "@pagerland/themes/src/RealEstate";
import Container from "@pagerland/common/src/components/Container";
import Exterior from '@pagerland/themes/src/RealEstate/containers/Exterior';
import preview from "@pagerland/themes/src/RealEstate/assets/backgroundcourses-1.jpg";

import PropTypes from "prop-types";

import { CartProvider, useCart } from "react-use-cart";

function ClearCart() {
  const { emptyCart } = useCart();
  useEffect(() => {
    emptyCart();
  }, [emptyCart]);
  return null;
}

export default function Thankyou({ url, pageContext, ContainerProps }) {

  return (
    <Theme>
      <Helmet>
        <link href={theme.typography.googleFont} rel="stylesheet" />
        <meta name="theme-color" content={theme.colors.primary} />
        <meta property="og:image" content={`${url}${preview}`} />
      </Helmet>
      <SEO title={"Montessori and Children Toys "} />
      <Layout>
        <Container  {...ContainerProps}>
          <CartProvider>
            <ClearCart />
          </CartProvider>
        </Container>
      </Layout>

      <Exterior name="venue" />
    </Theme>
  )
}

Thankyou.propTypes = {
  url: PropTypes.string,
  pageContext: PropTypes.shape({
    category: PropTypes.string,
  }),
};

Thankyou.defaultProps = {
  url: "https://www.instagram.com/practicalmontessori/",
};